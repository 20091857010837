import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.css"
import "./assets/css/reset.css"
import "./assets/fonts/iconfont.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'
import {api_BannerView, api_Appointment,} from "@/api/list";
Vue.use(MetaInfo)
Vue.use(ElementUI);
Vue.config.productionTip = false
// 获取banner图
Vue.prototype.getBanner = async function (param) {
    const data = await api_BannerView(param)
    return data.data.images
}
// 提交表单
Vue.prototype.submit = async function (name, phone, captcha, industry) {
    const data = await api_Appointment({name, phone, captcha, industry})
    if (data.status !== 200) {
        this.$message({
            message: data.msg,
            type: 'error',
            offset:100
        });
        // this.$message.error(data.msg);
    } else {
        this.$message({
            message: data.msg,
            type: 'success',
            offset:100
        });
    }
    return data
}
// //设置站点信息
// Vue.prototype.setSiteInfo = function (data) {
//     document.title = data.site_title||'';
//     //站点关键词
//     let meta2 = document.createElement('meta');
//     meta2.name = 'keywords';
//     meta2.content = data.keyword
//     head.appendChild(meta2);
//     //站点描述
//     let meta1 = document.createElement("meta");
//     let head = document.getElementsByTagName("head")[0];
//     meta1.name = 'description';
//     meta1.content = data.description;
//     head.appendChild(meta1);
//
// }

new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
