<template>
    <div id="app" class="flex flex-column row-between" :class="$route.path === '/'?'home_bg':''">
        <div class="headBox">
            <Head class="head center"></Head>
        </div>
        <div class="main">
            <router-view/>
        </div>
        <Foot class="foot"></Foot>
    </div>
</template>
<script>
    import Head from "@/components/Head";
    import Foot from "@/components/Foot"
    import {api_ConfigIndex} from "@/api/list";

    export default {
        name: 'App',
        metaInfo() {
            return {
                title:this.title,
                meta: [{
                    name: 'keywords',
                    content: this.keywords
                },{
                    name: 'description',
                    content: this.description
                }]
            }
        },
        data() {
            return {
                keywords: '',
                description: '',
                title:""
            }
        },
        components: {
            Head,
            Foot
        },
        mounted() {
            if (window.location.href.includes('https://')) {
                if (this._isMobile()) {
                    window.location.href = 'https://gw.weistar.net/h5/#/'
                }
            }
        },

        methods: {

            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            async api_ConfigIndex() {
                const data = await api_ConfigIndex()
                this.$store.commit('set_siteinfo', data.data)
                this.title = data.data.site_title || '';
                this.description = data.data.description
                this.keywords = data.data.keyword
            },
        },
        async created() {

            await this.api_ConfigIndex()
            // await this.setSiteInfo(this.seoData)
        },
    }
</script>
<style lang="less" scoped>

  .home_bg{
    background: #fff !important;
  }
  #app {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    .headBox{
      width: 100%;
      background: #fff;
      position: fixed;
      top: 0;
      z-index: 9999;
      box-shadow: 0px 2px 5px 0px rgba(98, 98, 98, 0.2);
    }
    .head{
      width: 1200px;
      flex: 0 0 60px;
    }
    .shadow{
      width: 100%;
      height: 2px;
      background: #42b983;

    }
    .main{
      flex: 1;
      background: #F5F5F5;
      padding-top: 61px;
    }
    .foot{
      //flex: 0 0 454px;
    }
  }

</style>
