import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// 登录：https://www.weistar.net/web/index.php?c=user&a=login&
//     注册、试试看：https://www.weistar.net/web/index.php?c=user&a=register&
//     其他定制：https://www.023net.net
//在线客服 http://p.qiao.baidu.com/cps/chat?siteId=16029576&userId=24824272&siteToken=ad16d954c25f698efe66bdc329ab28fb
export default new Vuex.Store({
  state: {
    baseURL:'https://api.weistar.net',
    other_address:'https://www.023net.net',
    login_address:'https://www.weistar.net/web/index.php?c=user&a=login',
    register_address:'https://www.weistar.net/web/index.php?c=user&a=register',
    service_address:'http://p.qiao.baidu.com/cps/chat?siteId=16029576&userId=24824272&siteToken=ad16d954c25f698efe66bdc329ab28fb',
    keywords:'',
    siteinfo:{
      category_name:'',
      service_number:''
    },
    offset:100,
  },
  mutations: {
    set_keywords(e,data){
      this.state.keywords = data
    },
    set_siteinfo(e,data){
      this.state.siteinfo = data
    }
  },
  actions: {
  },
  modules: {
  }
})
