<template>

    <div class="home font14">
        <div class="center">
            <div class="carousel">
                <el-carousel height="370px">
                    <el-carousel-item v-for="(item,index) in carouselList" :key="index">
                        <a :href="item.url">
                            <img alt="重庆小程序开发" :src="$store.state.baseURL+item.image">
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="titleBar text-center">
                <div class="font26 font-bold">微信营销，找微星球</div>
                <div class="font-ash86 font16">全面微信营销功能，为您开启高效快捷的网络营销</div>
            </div>
        </div>
        <div class="recommend">
            <div class="case flex col-center">
                <div class="caseMain">
                    <div class="block flex " :style="'transform: translateX('+distance+'px)'">
                        <div class="box" v-for="(item,index) in functionList" :key="index">
                            <div class="title font18 flex col-center">
                                <div></div>
                                <div>{{ item.category.category_name }}</div>
                            </div>
                            <div class="caseList">
                                <div @click="toDetailed(1,items.id)" class="caseItem flex col-center cursor"
                                     v-for="(items , i) in item.items"
                                     :key="i">
                                    <img :src="$store.state.baseURL+items.icon" alt="重庆微信商城开发">
                                    <div class="text">
                                        <div class="font18 text-over-1">{{ items.title }}</div>
                                        <div class="introduce font-ash86 text-over-1">{{ items.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div @click="paging(1)" class="leftArrow flex col-center row-center">
                    <div class="iconfont icon-you"></div>
                </div>
                <div @click="paging(2)" class="rightArrow flex col-center row-center">
                    <div class="iconfont icon-you"></div>
                </div>
            </div>

            <div class="contact flex flex-column row-center col-center">
                <div @click="jumpOut()" class="font-white text-center cursor">马上试试看</div>
                <div class="font-ash86">请致电：{{ $store.state.siteinfo.service_number }}</div>
            </div>
        </div>
        <div class="center">
            <div class="titleBar text-center">
                <div class="font26 font-bold">不好选择，我们来帮您</div>
                <div class="font-ash86 font16">丰富的功能使用场景，让您有明确的运营方向</div>
            </div>
            <div class="classify flex row-between">
                <div class="hotTopic">
                    <div class="font-ash86">今日热题</div>
                    <div class="hotList">
                        <div @click="toDetailed(2,item.id)" class="hotList_item flex col-center cursor"
                             v-for="(item ,index) in hotList"
                             :key="index">
                            <div class="font-white bg-red" v-if="index === 0">{{ index + 1 }}</div>
                            <div class="font-white bg-orange" v-if="index === 1">{{ index + 1 }}</div>
                            <div class="font-white bg-yellow" v-if="index === 2">{{ index + 1 }}</div>
                            <div class="font-white" v-if="index>2">{{ index + 1 }}</div>
                            <div class="text-over-1">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
                <div class="sort">
                    <div class="tab flex col-center">
                        <div class="tabList flex col-center">
                            <div @click="tab(item)"
                                 class="tabList_item font-ash86 text-center cursor"
                                 :class="current === item.id&&'activeColor'"
                                 v-for="(item ,index) in tabList"
                                 :key="index">
                                <div>{{ item.category_name }}</div>
                                <div class="effect " :class="current === item.id&&'effectActive'">
                                    <div class="horn" :class="current === item.id&&'hornActive'"></div>
                                </div>
                            </div>
                        </div>
                        <div @click="lookMore()" class="more font12 text-right cursor">查看更多内容 ></div>
                    </div>
                    <div v-if="list.length>0" class="list">
                        <div @click="toDetailed(2,item.id)" class="list_item flex cursor" v-for="(item , index) in list"
                             :key="index">
                            <img class="object_img" :src="$store.state.baseURL+item.cover" alt="重庆微信营销系统">
                            <div>
                                <div class="font18 text-over-1 texthidden">{{ item.title }}</div>
                                <div class="font-ash86 text-over-1 texthidden">{{ item.description }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="list.length<1" class="listTips font-ash86 font18 flex col-center row-center">暂无相关数据</div>
                </div>
            </div>
            <div class="titleBar text-center">
                <div class="font26 font-bold">十六年的行业经验，为您护航</div>
                <div class="font-ash86 font16">微星球直属“重庆互联网”，圣灵科技旗下品牌，十六年互联网行业经验</div>
            </div>
            <div class="experience">
                <div @click="toDetailed(3,item.id)" class="experience_item cursor" v-for="(item ,index) in caseList"
                     :key="index">
                    <img class="cover" :src="$store.state.baseURL+item.cover" alt="重庆公众号商城开发">
                    <div class="news flex">
                        <div class="text">
                            <div class=" text-over-1 font18 ">{{ item.title }}</div>
                            <div class="introduce font-ash86 text-over-2">{{ item.description }}</div>
                            <div class="font-ash86">行业：{{ item.industry }}</div>
                        </div>
                        <img class="code" :src="$store.state.baseURL+item.qrcode" alt="重庆网站建设">
                    </div>
                </div>
            </div>
            <div class="titleBar text-center">
                <div class="font26 font-bold">联系我们，享受优惠折扣</div>
                <div class="font-ash86 font16">免费店铺装修，提供多对一售后服务，赠送全套系统教学，可上门教学讲解</div>
            </div>
            <div class="customerService flex col-center">
                <div class="communicate flex col-center row-center">
                    <img src="../assets/imgs/kefu.png" alt="重庆网站搭建" >
                    <div class="flex flex-column col-center">
                        <div class="">
                            <span>服务热线：</span>
                            <span class="font20">{{ $store.state.siteinfo.service_number }}</span>
                        </div>
                        <div class="mail">
                            <span>邮件地址：</span>
                            <span class="font20">{{ $store.state.siteinfo.email }}</span>
                        </div>
                        <div>工作时间 {{ $store.state.siteinfo.work_time }}</div>
                    </div>
                </div>
                <div class="newsForm flex flex-column col-center">
                  <div class="inputTop flex col-center">
                    <input  v-model="name" type="text" placeholder="姓名">
                    <input v-model="phone" type="number" placeholder="联系电话">
                  </div>
                    <input class="industry" v-model="industry" type="text" placeholder="请输入行业（选填）">
                    <div class="code flex col-center borderBox row-between">
                        <input v-model="captcha" class="borderBox" type="text" placeholder="请输入验证码">
                        <img onClick="this.src=this.src+'?'+Math.random()"
                             :src="$store.state.baseURL + '/api'+'/Base/captcha'"
                             alt="重庆小程序商城开发">
                    </div>
                    <div class="btn flex col-center font-white text-center cursor">
                        <div @click="subscribe" class="subscribe">我要预约</div>
                        <div @click="jumpOut()" class="register">立即注册体验</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        api_FunctionCategory,
        api_IndustryHot,
        api_IndustryCategory,
        api_IndustryIndex,
        api_QualityIndex,

    } from "@/api/list"
    import {phoneReg} from "@/utils/reg"
    import {Throttle} from "@/utils/public";

    export default {

        data() {
            return {
                distance: 0,
                carouselList: [],
                functionList: [],
                hotList: [],
                tabList: [],
                list: [],
                caseList: [],
                functionCount: 0,
                functionPage: 1,
                functionLimit: 20,
                industryLimit: 5,
                industryListLimit: 3,
                caseLimit: 6,
                current: 0,
                captcha: '',
                name: '',
                phone: '',
                industry: '',
                swiperIndex: 1,
                onpresscTime: 0,
                Interval: null,
            }
        },
        async created() {

            // 获取轮播图
            await this.getBanner({id: 100}).then(e => {
                this.carouselList = e
            })
            await this.api_FunctionCategory()
            await this.api_IndustryHot()
            await this.api_IndustryCategory()
            await this.api_IndustryIndex()
            await this.api_QualityIndex()
        },
        methods: {
            tab(type) {
                if (this.current !==  type.id) {
                    this.current = type.id
                    this.api_IndustryIndex()
                }
            },
            // 外部跳转
            jumpOut() {
                window.location.href = this.$store.state.register_address
            },
            // 文章详情
            toDetailed(type, id) {
                this.$router.push({path: '/articleDetails?type=' + type + '&id=' + id})
            },
            // 预约
            subscribe: Throttle(function () {
              console.log(document.cookie)
              // console.log(this.getCookie('Set-Cookie'))
                const {name, phone, captcha, industry} = this
                if (!name) {
                    this.$message({
                        message: '请输入姓名',
                        type: 'warning',
                        offset:this.$store.state.offset
                    });
                } else if (!phone) {
                    console.log(2)
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset:this.$store.state.offset

                    });
                } else if (!phoneReg.test(phone)) {
                    console.log(3)
                    this.$message({
                        message: '请输正确的手机号',
                        type: 'warning',
                        offset:this.$store.state.offset

                    });
                } else if (!captcha) {
                    this.$message({
                        message: '请输入验证码',
                        type: 'warning',
                        offset:this.$store.state.offset
                    });
                } else {
                    this.submit(name, phone, captcha, industry)
                }
            },1000),
            // 查看更多内容
            lookMore() {
                this.$router.push({path: '/industry'})
            },
            // 分页切换

            paging: Throttle(function (type) {
                switch (type) {
                    case 1:
                        if (this.swiperIndex > 1) {
                            this.distance = this.distance + 1220
                            this.swiperIndex--
                        }
                        break
                    case 2:
                        if (this.swiperIndex < this.functionList.length / 2) {
                            this.swiperIndex++
                            this.distance = this.distance - 1220
                        }
                        break
                }
            }, 1000),


            // 功能列表
            async api_FunctionCategory() {
                const data = await api_FunctionCategory({limit: this.functionLimit, page: this.functionPage})
                this.functionList = data.data.list
                this.functionCount = data.data.count
            },
            // 今日热点
            async api_IndustryHot() {
                const data = await api_IndustryHot()
                this.hotList = data.data.list
            },
            // 行业分类
            async api_IndustryCategory() {
                const data = await api_IndustryCategory({limit: this.industryLimit})
                this.tabList = data.data.list
                this.current = data.data.list[0].id
            },
            // 分类列表
            async api_IndustryIndex() {
                const data = await api_IndustryIndex({limit: this.industryListLimit, industry_id: this.current})
                this.list = data.data.list
            },
            // 案例列表
            async api_QualityIndex() {
                const data = await api_QualityIndex({limit: this.caseLimit})
                this.caseList = data.data.rows
            },

        }
    }
</script>
<style lang="less" scoped>

    .activeColor {
        color: #275AFF;
    }

    //激活样式
    .effectActive {
        border-bottom: 1px solid #275AFF !important;
    }

    .hornActive {
        border-left: 1px solid #275AFF !important;
        border-top: 1px solid #275AFF !important;
    }

    .home {
        padding-bottom: 63px;
        background: #fff;

        .carousel {
            padding-top: 19px;

            a {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }
        }

        .titleBar {
            margin-top: 80px;

            div:last-child {
                margin-top: 18px;
            }

        }

        .recommend {
            .case {
                width: 1200px;
                height: 308px;
                margin: 59px auto 0;
                position: relative;

                .leftArrow, .rightArrow {
                    width: 40px;
                    height: 100px;
                    background: #F2F2F2;
                    border-radius: 4px;
                    color: #E1E1E1;

                    .iconfont::before {
                        font-size: 26px !important;
                    }
                }

                .leftArrow {
                    position: absolute;
                    left: -60px;

                    div {
                        transform: rotate(180deg);
                        -ms-transform: rotate(180deg); /* IE 9 */
                        -moz-transform: rotate(180deg); /* Firefox */
                        -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
                        -o-transform: rotate(180deg); /* Opera */
                    }
                }

                .rightArrow {
                    position: absolute;
                    right: -60px;
                }

                .caseMain {
                    height: 100%;
                    overflow: hidden;

                    .block {
                        width: 5000px;
                        transition: all 1s;

                        > div:not(:first-child) {
                            margin-left: 20px;
                        }
                    }

                    .box {
                        width: 590px;
                        height: 308px;
                        box-sizing: border-box;
                        padding: 20px 21px;
                        border: 1px solid #EBEBEB;
                        border-radius: 4px;

                        .title {
                            div:first-child {
                                width: 2px;
                                height: 20px;
                                background: #2B2B2B;
                                border-radius: 1px;
                                margin-right: 10px;
                            }
                        }

                        .caseList {
                            margin-top: 28px;
                            display: grid;
                            grid-template-columns: 252px 252px;
                            grid-row-gap: 40px;
                            grid-column-gap: 61px;
                            grid-template-rows: 46px 46px 46px;

                            .caseItem {
                                img {
                                    width: 46px;
                                    height: 46px;
                                    border-radius: 10px;
                                    margin-right: 16px;
                                }

                                .text {
                                    > div {
                                        width: 190px;

                                    }

                                    .introduce {
                                        margin-top: 9px;
                                    }
                                }

                            }
                        }
                    }
                }

                .rightArrow {
                    margin-left: 10px;
                }
            }

            .contact {
                margin-top: 40px;

                div:first-child {
                    width: 155px;
                    height: 40px;
                    line-height: 40px;
                    background: #275AFF;
                    border-radius: 4px;
                    margin-bottom: 15px;
                }
            }
        }

        .classify {
            margin-top: 60px;
            padding-bottom: 60px;
            border-bottom: 1px solid #EBEBEB;

            .hotTopic {
                .hotList {
                    margin-top: 25px;

                    .hotList_item {
                        margin-bottom: 20px;

                        div:first-child {
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                            background: #D5D5D5;
                            border-radius: 4px;
                            margin-right: 10px;
                            font-family: Microsoft YaHei;
                            font-style: italic;
                            box-sizing: border-box;
                            padding-left: 5px;
                        }

                        div:last-child {
                            width: 290px;
                        }
                    }
                }
            }

            .sort {
                flex: 1;
                margin-left: 88px;

                .tab {
                    .tabList {
                        flex: 1;

                        .tabList_item {
                            //width: 60px;
                            margin-right: 34px;

                            .effect {
                                height: 5px;
                                width: 100%;
                                border-bottom: 1px solid #fff;
                                margin-top: 11px;
                                position: relative;

                                .horn {
                                    width: 4px;
                                    height: 4px;
                                    border-left: 1px solid #fff;
                                    border-top: 1px solid #fff;
                                    transform: rotate(45deg);
                                    -ms-transform: rotate(45deg); /* IE 9 */
                                    -moz-transform: rotate(45deg); /* Firefox */
                                    -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                                    -o-transform: rotate(45deg);
                                    position: absolute;
                                    top: 2px;
                                    left: 45%;
                                    background: #fff;
                                    z-index: 1111;
                                }
                            }
                        }

                        .tabList_item:hover {
                            color: #275AFF;
                        }
                    }

                    .more {
                        flex: 0 0 100px;
                        color: #275AFF;
                        margin-bottom: 17px;
                    }
                }

                .list {
                    margin-top: 9px;

                    .list_item {
                        margin-bottom: 30px;
                        width: 790px;
                        img {
                            width: 80px;
                            height: 58px;
                            border-radius: 4px;
                            margin-right: 20px;
                        }

                        > div {
                            box-sizing: border-box;
                            padding: 6px 0 5px;

                            div:last-child {
                                margin-top: 14px;
                            }
                        }
                    }

                    .list_item:last-child {
                        margin: 0;
                    }
                }

                .listTips {
                    height: 100%;
                }
            }
        }

        .experience {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 432px 432px;
            grid-row-gap: 40px;
            grid-column-gap: 40px;
            margin-top: 59px;

            .experience_item:hover {
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.13);
            }

            .experience_item {
                border: 1px solid #EBEBEB;
                border-radius: 4px;

                .cover {
                    width: 100%;
                    height: 275px;
                    border-radius: 4px 4px 0px 0px;
                }

                .news {
                    box-sizing: border-box;
                    padding: 20px 23px 0;

                    .text {
                        width: 200px;

                        .introduce {
                            margin: 15px 0 30px;
                            line-height: 20px;
                            height: 40px;
                        }
                    }

                    .code {
                        width: 100px;
                        height: 100px;
                        margin-left: 33px;
                        background: #ccc;
                    }
                }
            }
        }

        .customerService {
            margin-top: 90px;

            > div {
                width: 50%;
                height: 266px;
            }

            .communicate {
                border-right: 1px solid #EBEBEB;

                img {
                    width: 72px;
                    height: 72px;
                    margin-right: 39px;
                }

                .mail {
                    line-height: 50px;
                }
            }

            .newsForm {
                input {
                    background: none;
                    outline: none;
                    padding: 0 10px;
                }

              .industry{
                width: 260px;
                height: 40px;
                border: 1px solid #DEDEDE;
                border-radius: 4px;
                margin-bottom: 14px;
              }
              .inputTop{
                width: 280px;
                height: 40px;
                margin-bottom: 14px;
                input{
                  height: 40px;
                  width: 50%;
                  border: 1px solid #DEDEDE;
                  border-radius: 4px;
                }
                input:first-child{
                  margin-right: 10px;
                }
              }
                .code {
                    width: 282px;
                    border: 1px solid #DEDEDE;
                    border-radius: 4px;
                    padding: 5px 5px 5px 10px;

                    input {
                        width: 150px;
                        height: 20px;
                        border: none;
                        margin-bottom: 0;
                        padding: 0 5px 0 0;
                    }

                    img {
                        flex: 0 0 96px;
                        height: 30px;
                    }
                }

                .btn {
                    width: 280px;
                  margin-top: 14px;
                    div {
                        flex: 1;
                        height: 40px;
                        line-height: 40px;
                        background: #275AFF;
                        border-radius: 4px;
                    }

                    .register {
                        background: #FF952B;
                        margin-left: 10px;
                    }
                }
            }
        }

    }
    .texthidden{
      width: 690px;
    }

</style>