import req from '../utils/request'

// 优质案例---------------------------------------------------------------------------
// 列表
export const api_QualityIndex = params => req.get('/QualityCasus/index', params)
// 详细
export const api_QualityView = params => req.get('/QualityCasus/view', params)

// 功能讲解---------------------------------------------------------------------------
// 列表
export const api_FunctionIndex = params => req.get('/FunctionShow/index', params)
// 详情
export const api_FunctionView = params => req.get('/FunctionShow/view', params)
// 产品与服务
export const api_FunctionSevice = params => req.get('/FunctionShow/productSeviceList', params)
// 带分类列表
export const api_FunctionCategory = params => req.get('/FunctionShow/withCategoryList', params)

// 友情链接---------------------------------------------------------------------------
// 列表
export const api_FriendlyIndex = params => req.get('/FriendlyLink/index', params)

// 基础接口---------------------------------------------------------------------------
//图片验证码地址
export const BaseCaptcha = params => req.get('/Base/captcha', params)

// 基础配置---------------------------------------------------------------------------
//列表
export const api_ConfigIndex = params => req.get('/Config/index', params)

// 行业分析---------------------------------------------------------------------------
//列表
export const api_IndustryIndex = params => req.get('/IndustryAnalysis/index', params)
//详情
export const api_IndustryView = params => req.get('/IndustryAnalysis/view', params)
//今日热点
export const api_IndustryHot = params => req.get('/IndustryAnalysis/hotTodayList', params)
//随机3条数据列表
export const api_IndustryRandom= params => req.get('/IndustryAnalysis/randomList', params)

// 行业分类---------------------------------------------------------------------------
// 列表
export const api_IndustryCategory= params => req.get('/IndustryCategory/index', params)

// 轮播图---------------------------------------------------------------------------
//获取轮播图
export const api_BannerView= params => req.get('/Banner/view', params)

//预约提交---------------------------------------------------------------------------
//表单提交
export const api_Appointment= params => req.post('/Appointment/submit', params)



