<template>
  <div class="foot font-foot-ash">
    <div class="center">
      <div class="link">
        <div class="font-white font18">友情链接</div>
        <div class="linkList">
          <a class="font-foot-ash font14" :href="item.url" v-for="(item,index) in link_list"
             :key="index">{{ item.title }}</a>
        </div>
      </div>
      <div class="contact flex ">
        <div class="phone">
          <img src="@/assets/imgs/logo1.png" alt="重庆手机商城开发">
          <div class=" font-white">
            <div class="font14">服务热线：</div>
            <div class="font18">{{$store.state.siteinfo.service_number}}</div>
          </div>
        </div>
        <div class="service">
          <div class="title font-white">产品与服务</div>
          <div class="serviceList">
            <div @click="toDetailed(item.id)" class="font-foot-ash font14 cursor"  v-for="(item,index) in service_list"
               :key="index">{{ item.title }}</div>
          </div>
        </div>
        <div class="QR_code flex row-between flex-column col-bottom">
          <div class="codeList flex col-center row-center font12">
            <div class="flex col-center flex-column" v-for="(item ,index) in codeList" :key="index">
              <img :src="item.src" alt="重庆公众号商城开发">
              <div>{{ item.text }}</div>
            </div>
          </div>
          <div class="report font12 text-center">违法不良信息举报，请发送邮件至：{{$store.state.siteinfo.email}}</div>
        </div>
      </div>
      <div class="copyright font12 text-center">Copyright © 2013-{{ myDate }}
        <a class="font-foot-ash" href="https://www.023net.net/">{{$store.state.siteinfo.copyright}}  </a>
        <a class="font-foot-ash" href="https://beian.miit.gov.cn/">{{$store.state.siteinfo.icp}} </a>
      </div>
    </div>
  </div>

</template>

<script>
import {api_FriendlyIndex, api_FunctionSevice} from "@/api/list"

export default {
  name: "Foot",
  data() {
    return {
      link_list: [],
      service_list: [],
      codeList:[
        {id: 0, text: '微星球公众号' ,src:require('@/assets/imgs/ewm1.jpg')},
        {id: 1, text: '重庆互联网公众号',src:require('@/assets/imgs/ewm2.jpg')},
        {id: 2, text: '圣灵科技公众号',src:require('@/assets/imgs/ewm3.jpg')},
      ],
      data:{},
      limit: 50
    }
  },
  computed: {
    myDate() {
      return new Date().getFullYear();
    }
  },
  created() {
    this.api_FriendlyIndex()
    this.api_FunctionSevice()
  },
  methods: {
    async api_FriendlyIndex() {
      const data = await api_FriendlyIndex({limit: this.limit})
      this.link_list = data.data.list
    },
    async api_FunctionSevice() {
      const data = await api_FunctionSevice()
      this.service_list = data.data.list
    },

    toDetailed(id){
      this.$router.push({path: '/articleDetails?type=1&id='+id})
    }
  }
}
</script>

<style lang="less" scoped>
.foot {
  width: 100%;
  background: #000;

  .link {
    padding: 39px 0 20px;
    border-bottom: 1px solid #282828;

    .linkList {
      margin-top: 14px;

      a {
        margin-right: 10px;
      }
    }
  }

  .contact {
    padding: 40px 0;
    border-bottom: 1px solid #282828;

    .phone {
      flex: 0 0 235px;

      img {
        width: 165px;
        height: 58px;
        margin-bottom: 20px;
      }

      > div {
        width: 165px;
        line-height: 24px;
        box-sizing: border-box;
        padding: 7px 13px;
        background: #275AFF;
        border-radius: 4px;
      }
    }

    .service {
      flex: 0 0 515px;

      .title {
        padding-bottom: 16px;
        border-bottom: 1px solid #282828;
      }
    }

    .serviceList {
      padding-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;
    }

    .QR_code {
      flex: 1;

      .codeList {
        > div {
          margin-left: 50px;
        }
      }

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 21px;
      }

    }

    .report {
      width: 357px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #282828;
      border-radius: 4px;
      margin-top: 21px;
    }
  }

  .copyright {
    height: 88px;
    line-height: 88px;
  }
}

</style>