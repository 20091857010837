import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/functionList',
        name: 'functionList',
        component: () => import(/* webpackChunkName: "about" */ '../views/functionList.vue')
    }, {
        path: '/industry',
        name: 'industry',
        component: () => import(/* webpackChunkName: "about" */ '../views/industry.vue')
    }, {
        path: '/case',
        name: 'case',
        component: () => import(/* webpackChunkName: "about" */ '../views/case.vue')
    }, {
        path: '/searchList',
        name: 'searchList',
        component: () => import(/* webpackChunkName: "about" */ '../views/searchList.vue')
    }, {
        path: '/articleDetails',
        name: 'articleDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/articleDetails.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})
export default router
