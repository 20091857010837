<template>
  <div class="nav flex col-center font-black">
    <div class="main center flex col-center row-between">
      <div class="logo flex col-center">
        <img alt="重庆微信开发" :src="$store.state.baseURL+$store.state.siteinfo.site_logo||''">
        <div class="phone">
          <div>服务热线：</div>
          <div>{{$store.state.siteinfo.service_number||''}}</div>
        </div>
      </div>
      <div class="operation_box cursor flex col-center">
        <div class="type flex col-center">
          <div class="font14" :class="$route.path === item.path?'nav_active':''" @click="nav(item)"
               v-for="(item,index) in nav_list" :key="index">{{ item.text }}
          </div>
        </div>
        <div class="search flex col-center">
          <input v-model="value" placeholder="请输入文章或功能...">
          <div class="flex col-center col-center">
            <div @click="search()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <div class="register flex col-center ">
          <el-dropdown @command="handleCommand">
            <a @click="login" class="login font-black">后台登录</a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">新系统</el-dropdown-item>
              <el-dropdown-item command="2">旧系统</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a :href="$store.state.register_address||''">免费注册</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Throttle} from "@/utils/public";

  export default {
  name: "Head",
  data() {
    return {
      nav_list: [
        {id: 0, text: '首页', path: '/'},
        {id: 1, text: '功能讲解', path: '/functionList'},
        {id: 2, text: '行业分析', path: '/industry'},
        {id: 3, text: '优质案例', path: '/case'},
        {id: 4, text: '更多服务', path: ''}
      ],
      value: '',
    }
  },
  created() {
    console.log(this.$store.state.login_address)
  },
  methods: {
    nav(data) {
      const {id, path} = data
      if (id < 4) {
        this.$router.push({path})
      } else {
        window.location.href = 'https://www.023net.net'
      }
    },
    search: Throttle(function () {
      if (!this.value){
        this.$message({
          message: '请输入关键字',
          type: 'warning',
          offset:this.$store.state.offset
        });
      }else {
        this.$store.commit('set_keywords',this.value)
        if (this.$route.path !== '/searchList'){
          this.$router.push({path: '/searchList'})
        }
      }
    },1000),
    login(){
      console.log(this.$store.state.login_address)
    },
    handleCommand(type){
      type = Number(type)
      switch (type){
        case 1:
          window.location = this.$store.state.login_address
          break
        case 2:
          window.location = 'http://wxq.sluzw.com/web/index.php?c=user&a=login&'
          break

      }
    }
  }
}
</script>

<style lang="less" scoped>
.nav_active {
  font-weight: bold !important;
  color: #275AFF !important;
}
.login{
  color: #2B2B2B !important;
  background: #fff !important;
}
.nav {
  width: 100%;
  height: 60px;
  background: #fff;

  .logo {
    .phone {
      color: #FF952B;

      div {
        line-height: 18px;
      }
    }
  }

  .main {
    img {
      width: 107px;
      height: 39px;
      margin-right: 16px;
    }

    .type {
      div {
        margin-left: 54px;
      }
    }

    .search {
      width: 176px;
      height: 30px;
      background: #F3F3F3;
      margin-left: 30px;
      border-radius: 4px;

      input {
        width: 129px;
        margin-left: 10px;
        border: none;
        background: #F3F3F3;
        outline: none
      }

      > div {
        width: 39px;
        color: #313131;
        justify-content: center;
      }
    }

    .register {
      margin-left: 14px;

      a {
        width: 70px;
        height: 60px;
        line-height: 60px;
        padding: 0 16px;
      }

      a:last-child {
        background: #275AFF;
        color: #fff;
      }
    }
  }
}

</style>